<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <div class="d-flex justify-space-between">
          <div class="d-flex" v-if="Lb_IsQSK">
            <v-btn @click="getXlsReport(2)" class="ma-2 mr-4" dark color="success">
              <v-icon>mdi-microsoft-excel</v-icon>
              {{ $t("general.ListOfQuestions") }}
            </v-btn>
            <v-switch
              v-model="b_ShowArchived"
              inset
              label="archivierte Fragen einblenden"
            ></v-switch>
          </div>
          <v-btn @click="routeToNewQuestion" class="ma-2" dark color="primary">
            <v-icon>mdi-plus</v-icon>
            {{ $t("general.new_question") }}
          </v-btn>
        </div>
        <v-card-title>
          {{ $t("general.Mini Cases") }}
          <v-spacer />
          <v-spacer />
          <v-spacer />
          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('general.search')"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          :search="search"
          :custom-filter="customSearch"
          :headers="headers"
          :items="a_QuestionsToDisplay"
          item-key="number"
          class="elevation-1 table-one"
          multi-sort
          :loading="Lb_Isloded"
          :loading-text="$t('general.please_wait')"
          :footer-props="{
            'items-per-page-text': $t('general.questions_per_page'),
          }"
          
          @update:items-per-page="GetOptions"
          :items-per-page="getFGQuestionsPerPage"
        >
          <template v-slot:item.s_Tag="{ item }">
            <div class="mt-2" v-html="item.s_Tag" />
          </template>
          <template v-slot:item.s_Designation="{ item }">
            <div class="mt-2" v-html="item.s_Designation" />
          </template>
          <template v-slot:item.s_De="{ item }">
            <div v-if="!item.s_De && Lb_Translator" class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="routeToEditQuestions(item.i_TestID, 1, 1, item.s_MainTestNumber)"
                    color="success"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon  success>mdi-plus-box</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("general.add") }}</span>
              </v-tooltip>
            </div>
            <div v-else-if="!item.s_De" class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="danger"  warning v-bind="attrs" v-on="on" >mdi-lock</v-icon>
                </template>
                <span>{{ $t("general.not_available") }}</span>
              </v-tooltip>
            </div>
            <div v-else class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="routeToEditQuestions(item.i_TestID, 1, 0, item.s_MainTestNumber)"
                    :color="getEditionProps(item).color"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon >mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ getEditionProps(item).label }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="getReport(item.i_TestID, 1, item.s_MainTestNumber)"
                    color="danger"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon >mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span>PDF</span>
              </v-tooltip>
              <v-tooltip top v-if="item.s_De && Lb_Translator">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="getDocxReport(item.i_TestID, 1, item.s_MainTestNumber)"
                    color="#1565C0"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon >mdi-file-word-box</v-icon>
                  </v-btn>
                </template>
                <span>Word</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.s_Fr="{ item }">
            <div v-if="!item.s_Fr && Lb_Translator" class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="routeToEditQuestions(item.i_TestID, 2, 1, item.s_MainTestNumber)"
                color="success"
                dark
                v-bind="attrs"
                icon
                v-on="on"
              >
                <v-icon  success>mdi-plus-box</v-icon>
              </v-btn>
            </template>
                <span>{{ $t("general.add") }}</span>
              </v-tooltip>
            </div>
            <div v-else-if="!item.s_Fr" class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="danger"  warning v-bind="attrs" v-on="on" >mdi-lock</v-icon>
                </template>
                <span>{{ $t("general.not_available") }}</span>
              </v-tooltip>
            </div>
            <div v-else class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="routeToEditQuestions(item.i_TestID, 2, 0, item.s_MainTestNumber)"
                    :color="getEditionProps(item).color"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon >mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ getEditionProps(item).label }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="getReport(item.i_TestID, 2, item.s_MainTestNumber)"
                    color="danger"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon >mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span>PDF</span>
              </v-tooltip>
              <v-tooltip top v-if="item.s_Fr && Lb_Translator">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="getDocxReport(item.i_TestID, 2, item.s_MainTestNumber)"
                    color="#1565C0"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon >mdi-file-word-box</v-icon>
                  </v-btn>
                </template>
                <span>Word</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.s_It="{ item }">
            <div v-if="!item.s_It && Lb_Translator" class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="routeToEditQuestions(item.i_TestID, 3, 1, item.s_MainTestNumber)"
                color="success"
                dark
                v-bind="attrs"
                icon
                v-on="on"
              >
              <v-icon  success>mdi-plus-box</v-icon>
              </v-btn>
              </template>
              <span>{{ $t("general.add") }}</span>
              </v-tooltip>
            </div>
            <div v-else-if="!item.s_It" class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="danger"  warning v-bind="attrs" v-on="on" >mdi-lock</v-icon>
                </template>
                <span>{{ $t("general.not_available") }}</span>
              </v-tooltip>
            </div>
            <div v-else class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="routeToEditQuestions(item.i_TestID, 3, 0, item.s_MainTestNumber)"
                    :color="getEditionProps(item).color"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon >mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ getEditionProps(item).label }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="getReport(item.i_TestID, 3, item.s_MainTestNumber)"
                    color="danger"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon >mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span>PDF</span>
              </v-tooltip>
              <v-tooltip top v-if="item.s_It && Lb_Translator">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="getDocxReport(item.i_TestID, 3, item.s_MainTestNumber)"
                    color="#1565C0"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon >mdi-file-word-box</v-icon>
                  </v-btn>
                </template>
                <span>Word</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.i_Released="{ item }">
            <template v-if="item.i_Released == 1">
              <v-chip color="white" label small text-color="success">
                <v-icon> mdi-check </v-icon>
              </v-chip>
            </template>
          </template>
          <template v-slot:item.s_Status="{ item }">
            <v-chip :color="getStatusLabel(item.s_Status).color" label small text-color="white">
              {{ getStatusLabel(item.s_Status).label }}
            </v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex flex-row">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="copyRow(item.i_TestID)"
                    color="primary"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon >mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("general.copy") }}</span>
              </v-tooltip>
              <v-tooltip top v-if="item.i_Released == 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="routeToQuestionsComments(item.i_TestID)"
                    color="success"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                  <v-icon v-if="item.i_Has_Comment==0"  >mdi-comment-outline</v-icon>
                  <v-icon v-if="item.i_Has_Comment==1"  >mdi-comment-alert</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("general.comments") }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="routeToQuestionsHistory(item.i_TestID)"
                    color="primary"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon >mdi-history</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("general.protocol") }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="deleteQuestionDialog(item.i_TestID)"
                    color="danger"
                    dark
                    v-bind="attrs"
                    icon
                    :disabled="item.b_QuestionBlocked"
                    v-on="on"
                  >
                    <v-icon >mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("general.delete") }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("general.you_will_delete_a_question") }}!
            </v-card-title>
            <v-card-text class="black--text"
              >{{ $t("general.would_you_like_to_continue") }}?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="Lb_Deleting"
                :disabeld="Lb_Deleting"
                color="green darken-1"
                text
                @click="(dialog = false), (Li_QuestionIDToBeDeleted = null)"
              >
                {{ $t("general.no") }}
              </v-btn>
              <v-btn
                :loading="Lb_Deleting"
                :disabeld="Lb_Deleting"
                color="red darken-1"
                text
                @click="deleteQuestion"
              >
                {{ $t("general.delete") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </base-card>
    </v-col>
    <v-dialog
      v-model="Lb_GetDocDialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="white--text">
          {{$t('general.please_wait')}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { config } from '../../data/config';
import { get, post, getFile } from "../../worker/worker-api";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FGQuestionsList",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: this.$t("general.Mini Cases"),
    };
  },
  props: {
    i_TestTypeID: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      Lb_GetDocDialog: false,
      Lb_IsQSK: false,
      Lb_Translator: false,
      Lb_Deleting: false,
      Lb_Isloded: false,
      dialog: false,
      Li_QuestionIDToBeDeleted: null,
      search: "",
      Lo_Questions: [],
      b_ShowArchived: false,
      o_Fields: {
        status: true,
        author: true,
        language: true,
        advancedModule: true,
        electiveModule: true,
        tag: true,
        designation: true,
        description: true,
        role: true,
        initialSituation: true,
        statement: true,
        solution: true,
        questions: true
      }
    };
  },
  computed: {
    ...mapGetters(['getRoles', 'getUserID','getFGQuestionsPerPage', 'getLastSearchMC']),
    headers() {
      return [
        {
          text: this.$t("general.number_abbreviation"),
          align: "start",
          sortable: true,
          value: "s_MainTestNumber",
        },
        {
          text: this.$t("general.tag"),
          value: "s_Tag",
          sortable: true,
        },
        { text: this.$t("general.short_description"), value: "s_Designation" },
        { text: "De", value: "s_De" },
        { text: "Fr", value: "s_Fr" },
        { text: "IT", value: "s_It" },
        {
          text: this.$t("general.release"),
          value: "i_Released",
          sortable: true,
        },
        { text: this.$t("general.status"), value: "s_Status" },
        { text: this.$t("general.actions"), value: "action", sortable: false, },
      ];
    }, 
    a_QuestionsToDisplay () {
      const La_Questions = this.Lo_Questions.map(question => ({
        ...question, b_QuestionBlocked: (question.i_InEditingPersonID !== 0 
            && question.i_InEditingPersonID !== null 
            && parseInt(this.getUserID) !== question.i_InEditingPersonID) ? true : false
      }))
      if (this.b_ShowArchived) return La_Questions
      return La_Questions.filter(question => question.s_Status !== 5)
    },
  },
  watch: {
    "$i18n.locale": function () {
      this.getTestslist();
    },
    search(val) {
      this.setLastSearchMC(val)
    }
  },
  methods: {
    ...mapActions(['changeQuestionsPerPage', 'setLastSearchMC']),
    GetOptions(Ai_ItemsPerPage){
      let Lo_Options = {
        i_itemsPerPage: Ai_ItemsPerPage,
        i_QuestionsTypID: 2,//2 = FG
      } 
      this.changeQuestionsPerPage(Lo_Options);
    },
    getXlsReport(Ai_TestTypeID) {
      this.Lb_GetDocDialog = true;
      getFile(
        `${process.env.VUE_APP_SMT_API_URL}/data/${Ai_TestTypeID}/xlsreport`,
        {}
      )
        .then((response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename
          a.download = "Liste der Fragen.xlsx";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.Lb_GetDocDialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.Lb_GetDocDialog = false;
        });
    },
    getDocxReport(Ai_TestID, Ai_LangID, As_Number) {
      let Ls_Lang = "";
      switch (Ai_LangID){
        case 1:
          Ls_Lang = "de";
          break;
        case 2:
          Ls_Lang = "fr";
          break;
        case 3:
          Ls_Lang = "it";
          break;  
      }
      this.Lb_GetDocDialog = true;
      getFile(
        `${process.env.VUE_APP_SMT_API_URL}/data/${Ai_TestID}/docxreport/${Ai_LangID}`,
        {}
      )
        .then((response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          let url = URL.createObjectURL(blob);
          //window.open(url);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename
          a.download = `${As_Number}_${Ls_Lang}.docx`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.Lb_GetDocDialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.Lb_GetDocDialog = false;
        });
    },
    getReport(Ai_TestID, Ai_LangID,As_Number) {
      let Ls_Lang = "";
      switch (Ai_LangID){
        case 1:
          Ls_Lang = "de";
          break;
        case 2:
          Ls_Lang = "fr";
          break;
        case 3:
          Ls_Lang = "it";
          break;  
      }
      this.Lb_GetDocDialog = true;
      getFile(
        `${process.env.VUE_APP_SMT_API_URL}/data/${Ai_TestID}/report/${Ai_LangID}`,
        {}
      )
        .then((response) => {
          let blob = new Blob([response], { type: "application/pdf" });
          let url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename
          a.download = `${As_Number}_${Ls_Lang}.pdf`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.Lb_GetDocDialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.Lb_GetDocDialog = false;
        });
    },
    routeToQuestionsHistory(Ai_TestID) {
      this.$router.push({
        name: "questions-history",
        params: { i_TestID: Ai_TestID },
      });
    },
    routeToQuestionsComments(Ai_TestID) {
      this.$router.push({
        name: "questions-comments",
        params: { i_TestID: Ai_TestID, i_TestTypeID: 2 },
      });
    },
    routeToNewQuestion() {
      if (this.i_TestTypeID === config.idSettings.testType.i_MC) {
        this.o_Fields.status = true
        this.o_Fields.author = true
        this.o_Fields.language = true
        this.o_Fields.advancedModule = false
        this.o_Fields.electiveModule = false
        this.o_Fields.tag = false
        this.o_Fields.designation = true
        this.o_Fields.description = true
        this.o_Fields.role = false
        this.o_Fields.initialSituation = true
        this.o_Fields.statement = true
        this.o_Fields.solution = true
        this.o_Fields.questions = false
      }
      this.$router.push({ 
        name: "new-question", 
        params: { 
          Pi_TestTypeID: this.i_TestTypeID,
          o_Fields: this.o_Fields
        } 
      })
    },
    routeToEditQuestions(Ai_TestID, Ai_LangID, Ai_Translation, As_Number) {
      this.$router.push({
        name: "test",
        params: {
          i_TestID: Ai_TestID,
          i_LangID: Ai_LangID,
          i_Translation: Ai_Translation,
          i_TestTypeID: this.i_TestTypeID,
         // i_TestTypeID: 2,
          s_Number: parseInt(As_Number),
        },
      });
    },
    customSearch(value, search, item) {
      return Object.values(item).some(
        (v) =>
          (v && v.toString().toLowerCase().includes(search.toLowerCase())) ||
          v.toString().toUpperCase().includes(search.toUpperCase())
      );
    },
    deleteQuestionDialog(Ai_QuestionID) {
      this.dialog = true;
      this.Li_QuestionIDToBeDeleted = Ai_QuestionID;
    },
    deleteQuestion() {
      this.Lb_Deleting = true;
      post(`${process.env.VUE_APP_SMT_API_URL}/teacher/delete-question`, {
        i_TestID: this.Li_QuestionIDToBeDeleted,
      })
        .then((response) => {
          this.getTestslist();
          this.Lb_Deleting = false;
          this.dialog = false;
          this.Li_QuestionIDToBeDeleted = null;
          this.getTestslist();
        })
        .catch((errors) => {
          console.log("submit Worker error: ", errors);
          this.Lb_Deleting = false;
          this.dialog = false;
          this.Li_QuestionIDToBeDeleted = null;
        });
    },
    copyRow: function (Ai_TestID) {
      this.Lb_Isloded = true;
      post(`${process.env.VUE_APP_SMT_API_URL}/teacher/set-question-copy`, {
        i_TestID: Ai_TestID,
      })
        .then((response) => {
          this.getTestslist();
          this.Lb_Isloded = false;
        })
        .catch((errors) => {
          console.log("submit Worker error: ", errors);
        });
    },
    getTestslist() {
      this.Lb_Isloded = true;
      get(`${process.env.VUE_APP_SMT_API_URL}/teacher/questions-list`, {
        i_QuestionstypID: this.i_TestTypeID, 
        i_PersonID: this.getUserID,
        s_Lang: this.$i18n.locale,
      })
        .then((response) => {
          if (response.status === 401) {
            //this.signOut();
            //this.$smt.logout();
            return;
          }
          this.Lo_Questions = response.a_Questions;
          this.Lb_Isloded = false;
          for (let i = 0; i < this.Lo_Questions.length; i++) {
            for (let j = 0; j < this.$t("a_Tags").length; j++) {
              if (this.Lo_Questions[i].s_Tag === this.$t("a_Tags")[j].s_Id) {
                this.Lo_Questions[i].s_Tag = this.$t("a_Tags")[j].s_Text;
              }
            }
          }
        })
        .catch((error) => {
          console.log("Worker error: ", error);
        });
    },
    getTranslatorRole() {
      let result = this.getRoles.filter(
        (Roles) => Roles.s_Role == "translator" || Roles.s_Role == "qsk"
      );
      if (result.length > 0) {
        this.Lb_Translator = true;
      }
    },
    getQSKRole() {
      let result = this.getRoles.filter((Roles) => Roles.s_Role == "qsk");
      if (result.length > 0) {
        this.Lb_IsQSK = true;
      }
    },
    getStatusLabel(Ai_StatusID) {
      switch (Ai_StatusID) {
        case 0:
          return {
            color: 'warning',
            label: this.$t('general.draft')
          }
          break;
        case 1:
          return {
            color: 'warning',
            label: 'QSK'
          }
          break;
        case 2:
          return {
            color: 'warning',
            label: this.$t('general.in_translation')
          }
          break;
        case 3:
          return {
            color: 'success',
            label: this.$t('general.definitely')
          }
          break;
        case 4:
          return {
            color: 'teal lighten-1',
            label: this.$t('general.proofreading')
          }
          break;
        case 5:
          return {
            color: 'grey',
            label: this.$t('general.archived')
          }
          break;
        default:
          break;
      }
    },
    getEditionProps(Ao_Question) {
      if (Ao_Question.b_QuestionBlocked) return {
        color: 'grey',
        label: this.$t('general.in_editing_by') + Ao_Question.s_InEditingName
      }
      else return {
        color: 'success',
        label: this.$t("general.edit")
      }
    },
  },
  created() {
    this.getTestslist();
    this.getTranslatorRole();
    this.getQSKRole();
    this.search = this.getLastSearchMC
  },
};
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 14px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
